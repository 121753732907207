<template>
  <div>
    <div class="header">发票信息</div>
    <div class="info"><a-alert message="如需修改发票抬头请联系您的客户经理" type="success" show-icon /></div>
    <div class="main-title">发票抬头</div>
    <ul class="main-content" v-if="baseInfo">
      <li>企业名称：{{baseInfo.name}}</li>
      <li>纳税人识别码：{{baseInfo.creditCode}}</li>
      <li>注册地址：{{baseInfo.address}}</li>
      <li>注册电话：{{baseInfo.phone}}</li>
      <li>开户银行：{{baseInfo.bankName}}</li>
      <li>银行账户：{{baseInfo.bankNo}}</li>
    </ul>
    <div class="main-title">寄送发票地址
      <!-- <a-button type="link" @click="update">修改</a-button> -->
    </div>
    <ul class="main-content" v-if="sjrInfo">
      <li>收票人姓名：{{sjrInfo.contactsName}}</li>
      <li>收票人手机号：{{sjrInfo.contactsPhone}}</li>
      <li>收票人地址：{{sjrInfo.contactsAddress}}</li>
    </ul>
    <!-- <a-form v-else :form="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 6 }" @submit="handleSubmit">
      <a-form-item label="收票人姓名" style="margin-bottom:10px">
        <a-input
          v-decorator="['name', { rules: [{ required: true, message: '请输入收票人姓名!' }] }]"
          placeholder="请输入收票人姓名"
        />
      </a-form-item>
      <a-form-item label="收票人电话" style="margin-bottom:10px">
        <a-input
          v-decorator="['phone', { rules: [{ required: true, message: '请输入收票人电话!' }] }]"
          placeholder="请输入收票人电话"
        />
      </a-form-item>
      <a-form-item label="收票人地址" style="margin-bottom:0">
        <a-input
          v-decorator="['address', { rules: [{ required: true, message: '请输入收票人地址!' }] }]"
          placeholder="请输入收票人地址"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 2 }" style="margin-top:20px">
        <a-button type="primary" html-type="submit">
          修改
        </a-button>
        <a-button style="margin-left:10px" @click="cancel">
          取消
        </a-button>
      </a-form-item>
    </a-form> -->
  </div>
</template>
<script>
import {
  getInvoiceMailed,
  ticketInfos
} from '@/api/api'
export default {
  data() {
    return {
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      isUpdate: false,
      sjrInfo: null,
      baseInfo:null,
    };
  },
  created () {
    this.getInvoiceMailed()
    this.getTicketBaseInfo()
    
  },
  methods: {
    // 获取发票信息
    getTicketBaseInfo () {
      ticketInfos().then(res => {
        if(res.success) {
          this.baseInfo = res.result
        }
      })
    },
    // 查询收件人信息
    getInvoiceMailed () {
      getInvoiceMailed().then(res => {
        if (res.success) {
          this.sjrInfo = res.result
        }
      })
    },
    // 修改
    update () {
      setTimeout(() => {
        this.form.setFieldsValue({
          name: this.sjrInfo.contactsName,
          phone: this.sjrInfo.contactsPhone,
          address: this.sjrInfo.contactsAddress,
        })
      },100)
      this.isUpdate = true
    },
    // 提交
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log('Received values of form: ', values);
        }
      });
    },
    // 取消
    cancel () {
      this.isUpdate = false
    }
  }
}
</script>
<style scoped lang="less">
.header{
  font-size: 24px;
  color: #202020;
}
.info{
  margin-top: 15px;
}
.main-title{
  font-size: 16px;
  color: #000000;
  margin: 39px 0 26px 0;
  display: flex;
  align-items: center;
}
.main-content{
  padding: 0;
  list-style: none;
  li{
    margin-bottom: 10px;
    font-size: 14px;
    color: #202020;
  }
}
</style>